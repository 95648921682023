html {
  scroll-behavior: smooth;
   min-width: 300px; 
   overflow-x: hidden;
   zoom: 80%;
}

body {
  margin: 0;
  background-color: white;
  width: 100%;
  height: auto;
  font-family: Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

#half-width{
  width: 50%
}

.column {
  float: left;
}

.center {
  width: 100%;
  text-align: center;
  margin: auto;
}

.column.left66-1 {
  width: 66%;
}

.column.right33-2 {
  width: 33%;
}

.column.left {
  width: 50%;
}

.column.right {
  width: 50%;
}

.column.left66 {
  width: 66%;
}

.column.right33 {
  width: 33%;
}

.column.left33 {
  width: 33%;
}

.column.center33 {
  width: 33%;
}

.column.left1 {
  width: 25%;
}

.column.left2 {
  width: 25%;
}

.column.right1 {
  width: 25%;
}

.column.right2 {
  width: 25%;
}

.column.right15 {
  width: 15%;
}

.column.right35 {
  width: 35%;
}

.footer {
  background-color: #939598;
  width: 100%;
  margin: 0;
  padding-left: 17%;
  padding-right: 17%;
  padding-top: 2%;
}

.footer div {
  width: auto;
  height: fit-content;
}

.footer-icon {

  object-fit: contain;
  height: auto;
  width: 100%;
  max-width: 250px;
  padding: 0.25em;

}

.footer-icon.maceng {

  object-fit: contain;
  height: auto;
  width: 80%;
}

.logo-stack{
  margin-bottom: 50px;
}

.social-media-icon {
  margin-bottom: 1.3750em;
  margin-top: 0.25em;
  margin-left: auto;
  margin-right: auto;
  max-width: 50px;
  max-height: 50px;
 }

.row:after {
  content: "";
  display: table;
  clear: both;
}

.containers {
  width: 95%;
  margin: 0 auto;
  margin-top: 120px;
}

.containers div {
  margin-bottom: 20px;
  scroll-margin-top: 105px;
  overflow-y: none;
}  

.containers.section {
  width: auto;
  height: fit-content;
  margin-top: 35px;
  overflow-y: none;
}  

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.subtitle {
  color: #781214;
  font-size: 2.5em;
  margin: 0;
  padding: 10px;
  font-style: Verdana;
  width: fit-content;
}

.subtitle.center {
  margin-left: auto;
  margin-right: auto;
}

.description {
  color: black;
  font-size: 1.25em;
  text-align: left;
  margin: 0;
  padding: 10px;
  font-style: Verdana;
  width: fit-content;
}

.sponsor-group {
  margin-top: 50px;
}

.title-sponsor-logo {
  object-fit: contain;
  width: 66%;
  height: 170px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.platnium-sponsor-logo {
  object-fit: contain;
  width: 100%;
  height: 155px;
}

.gold-sponsor-logo {
  object-fit: contain;
  width: 100%;
  height: 140px;
}

.silver-sponsor-logo {
  object-fit: contain;
  width: 100%;
  height: 120px;
}

.bronze-sponsor-logo {
  object-fit: contain;
  width: 100%;
  height: 100px;

  padding-right: clamp(2px, 15%, 25%);
  padding-left: clamp(2px, 15%, 25%);
  padding-top: clamp(2px, 20%, 20%);
  padding-bottom: clamp(2px, 20%, 20%);
}

.header-logo {
  max-width: 300px;
  max-height: 150px;
}


.image {
  object-fit: contain;
  width: 100%;
  min-width: 500px;
  margin-top: 25px;
}

.image.sae {
  min-width: 300px;
}

.image.team {
  box-shadow: 2px 4px 8px 2px #781214ab, 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 15px;
}

.image.tall {
  object-fit: contain;
  height: 100%;
  margin-top: 25px;
  max-height: 500px;
}

.image.small {
  height: 150px;
}

.icon {
  max-width: 150px;
  max-height: 150px;
}

.medium-icon {
  max-width: 200px;
  max-height: 150px;
}

figure.item {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  width: fit-content;
  margin-bottom: 10px;
}

.caption {
  display: block;
  color: #494B4B;
  font-size: 1.75em;
  font-style: Verdana;
  margin-top: 15px;
}

.social-media-icon-container{
  text-align: center;
  display: grid;
  margin-left: auto;
  margin-right: auto;
}

.contact-container {
  text-align: left;
  margin-left: 10px;
  margin-top: 0px;
}

.contact-container h6{
  font-size: 1.5em;
}

.contact-container ul{
  padding: 0;
  list-style-type: none;
}

.contact-container address {
  color: black;
  font-size: 20px;
  text-decoration: none;
}

.contact-container a:hover {
  color: #781214;
}

.header {
  display: block;
  overflow: hidden;
  background-color:  #f1f1f1;
  padding: 20px 10px;
  position: fixed;
  top: 0;
  width: 100%;
  height: 105px;
}

.header-left{
  float: left;
}

.header a {
  color: black;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 1.4em;
  line-height: 25px;
  border-radius: 4px;
}

.header a.logo {
  font-size: 1.5em;
  font-weight: bold;
}

.header a.active {
  background-color: #781214;
  color: white;
}

.header-right {
  float: right;
  padding-top: 25px;
}

.shad {
  box-shadow: 1px 2px 4px 1px rgb(27, 27, 27);
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
}

.shad:hover {
  box-shadow: 3px 6px 12px 3px #781214ab, 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

 .shadow {
  
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
 }

.shadow::after {
  border-radius: 5px;
  width: 100%;
  height: 100%;
} 

.shadow:hover {
  box-shadow: 2px 4px 8px 2px #781214ab, 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.triangle-bottomright{
  width: 0;
  height: 0;
  border-left: 250px solid transparent;
  float: left;
}
.triangle-bottomleft{
  width: 0;
  height: 0;
  border-right: 250px solid transparent;
  float: right;
}

.black{ 
  border-bottom: 20px solid #000000;
}

.platnium{ 
  border-bottom: 20px solid #B4B4B4;
}

.gold{ 
  border-bottom: 20px solid #C9B037;
}

.silver{ 
  border-bottom: 20px solid #D7D7D7;
}

.bronze{ 
  border-bottom: 20px solid #6A3805;
}

.title-sponsor {
  font-size: 40px;
  margin: 0;
  padding: 0;
}

.platnium-sponsor {
  font-size: 36px;
  margin: 0;
  padding: 0;
}

.gold-sponsor {
  font-size: 32px;
  margin: 0;
  padding: 0;
}

.silv-bronz-sponsor {
  font-size: 30px;
  margin: 0;
  padding: 0;
}

#sponsor-triangle{
  padding-top: 10px;
}


@media screen and (max-width: 1050px) {

  body {
    width: 100%;
    overflow: hidden;
  }

  .image {
    object-fit: contain;
    width: 100%;
    max-width: 66%;
  }

  .column {
    float: none;
  }

  .column.left {
    width: 100%;
  }
  
  .column.right {
    width: 100%;
  }

  .column.left.g {
    width: 50%;
  }
  
  .column.right.g {
    width: 50%;
  }

  .column.left66-1 {
    width: 100%;
  }
  
  .column.right33-2 {
    width: 100%;
  }

  .image.sae {
    min-width: 500px;
  }

  .header-title {
    max-width: 200px;
    max-height: 120px;
  }

  .header {
    overflow: hidden;
    background-color:  #f1f1f1;
    padding: 10px 5px;
    position: fixed;
    top: 0;
    width: 100%;
    height: 90px;
  }
  
  .header-left{
    float: left;
  }
  
  .header a {
    color: black;
    text-align: center;
    padding: 8px;
    text-decoration: none;
    font-size: 1.4em;
    line-height: 25px;
    border-radius: 4px;
  }
  
  .header a.logo {
    font-size: 1.5em;
    font-weight: bold;
  }
  
  .header a.active {
    background-color: #781214;
    color: white;
  }
  
  .header-right {
    float: right;
    padding-top: 25px;
  }

  .sponsor-group {
    margin-top: 35px;
  }

  .flex-display{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  }
  .row-bronze {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .column {
    flex: 1;
  }

  .footer {
    padding-left: 5;
    padding-right: 5%;
  }

  .contact-container h6{
    font-size: 14px;
  }

  .contact-container address {
    font-size: 12px;
  }

}

@media screen and (max-width: 730px) {

  body {
    width: 100%;
    overflow: hidden;
  }

  .containers {
    width: 95%;
    margin: 0 auto;
    margin-top: 160px;
  }
  
  .containers div {
    margin-bottom: 20px;
    scroll-margin-top: 160px; /* Height + 20px*/
  }  

  .image {
    min-width: 350px;
  }

  .subtitle {
    text-align: center;
    font-size: 2.7em;
  }

  .header {
    height: 160px;
  }

  .header-right {
    float: left;
    margin-left: 10px;
  }

  .image {
    object-fit: contain;
    width: 100%;
    max-width: 66%;
  }

  .sponsor-group {
    margin-top: 25px;
  }



  .triangle-bottomright {
    visibility: hidden;
  }

  .triangle-bottomleft {
    visibility: hidden;
  }

  .column {
    float: none;
  }

  .column.left {
    width: 100%;
  }
  
  .column.right {
    width: 100%;
  }

  .column.left.g {
    width: 100%;
  }
  
  .column.right.g {
    width: 100%;
  }

  .column.left66-1 {
    width: 100%;
  }
  
  .column.right33-2 {
    width: 100%;
  }
  
  .column.left66 {
    width: 100%;
  }
  
  .column.right33 {
    width: 100%;
  }
  
  .column.left33 {
    width: 100%;
  }
  
  .column.center33 {
    width: 100%;
  }

  .footer {
    padding-left: 5%;
    padding-right: 5%;
  }

  .contact-container h6{
    font-size: 16px;
  }

  .contact-container address {
    font-size: 12px;
  }

  .social-media-icon {
    max-width: 40px;
    max-height: 40px;
  }

  .title-sponsor-logo {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  
  .platnium-sponsor-logo {
    width: 100%;
  }

} 

@media screen and (max-width: 600px) {

  body {
    width: 100%;
    overflow-x: hidden;
  }

  .containers {
    margin-top: 25px;
  }

  .containers {
    margin-top: 80px;
  }
  
  .containers div {
    margin-bottom: 10px;
    scroll-margin-top: 100px; /* Height + 20px*/
  }  

  .image.sae {
    min-width: 250px;
  }

  .subtitle {
    text-align: center;
  }

  .header {
    height: 90px;
  }

  .header-right {
    display: none;
  }

  .header-left {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }

  .icon {
    max-width: 100px;
    max-height: 100px;
  }

  .caption {
  font-size: 1.35em;
  }

/* 
  .header-logo {
    max-width: 120px;
    max-height: 60px;
  } */

  .image {
    object-fit: contain;
    width: 100%;
    max-width: 66%;
  }

  #sponsor-triangle {
    display: none;
  }

  .column {
    float: none;
  }

  .column.left {
    width: 100%;
  }
  
  .column.right {
    width: 100%;
  }

  .column.left66-1 {
    width: 100%;
  }
  
  .column.right33-2 {
    width: 100%;
  }
  
  .column.left66 {
    width: 100%;
  }
  
  .column.right33 {
    width: 100%;
  }
  
  .column.left33 {
    width: 100%;
  }
  
  .column.center33 {
    width: 100%;
  }

  .column.left1 {
    width: 50%;
    margin-bottom: 10px;
  }
  
  .column.left2 {
    width: 50%;
    margin-bottom: 10px;
  }

  .column.right15 {
    width: 50%;
  }
  
  .column.right35 {
    width: 50%;
  }

  .footer {
    padding-left: 5%;
    padding-right: 5%;
  }

  .footer-icon {
    max-width: 150px;
    height: auto;
  }

  .contact-container h6{
    /* font-size: 24px; */
    font-size: 16px;
  }

   .contact-container {
    padding-left: 6%; /* Align with eng logo */
  } 

  .contact-container address {
    font-size: 12px;
  }

  .social-media-icon {
    max-width: 50px;
    max-height: 50px;
  }

  .sponsor-group {
    margin-top: 5px;
  }

  .platnium-sponsor{
    font-size: 36px;
  }

  .gold-sponsor {
    font-size: 34px;
  }

  .silv-bronz-sponsor {
    font-size: 32px;
  }

} 

